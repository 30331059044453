import Repository from './Repository';

const resource = 'users';

export default {
    resetPassword(data: any) {
        return Repository.post(`${resource}/reset-password`, data);
    },
    changePassword(data: any) {
        return Repository.post(`${resource}/change-password`, data);
    }
};
