import {Options, Vue} from 'vue-class-component';
import Button from '@/components/button/button.vue';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import {
    required,
    minLength,
    helpers,
    sameAs,
    maxLength
} from '@vuelidate/validators';
import UserRepository from '@/repositories/UserRepository';
import { AxiosResponse } from 'axios';
import { passwordValid } from '@/utils/password-validations';

@Options({
    components: {
        'app-input': Input,
        'app-button': Button
    },
    validations() {
        return {
            oldPassword: {
                required: helpers.withMessage(
                    'Ingrese la contraseña temporal',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                )
            },
            password: {
                required: helpers.withMessage(
                    'Ingrese una nueva contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            },
            confirmPassword: {
                required: helpers.withMessage(
                    'Ingrese la confirmación de la nueva contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                sameAsPassword: helpers.withMessage(
                    'La confirmación de la contraseña debe ser igual a la otra contraseña',
                    sameAs(this.password)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            }
        };
    }
})
export default class RecoverPassword extends Vue {
    private appElement: HTMLElement | null = null;
    public oldPassword: string = '';
    public password: string = '';
    public confirmPassword: string = '';
    public email: string = '';
    public urlFrom: string = '';
    public isBtnLoading: boolean = false;
    private toast = useToast();
    v$: any = useVuelidate();
    showUserOldPassword:boolean = false;
    showUserPassword:boolean = false;
    showUserPasswordConfirm:boolean = false;

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
        this.v$.$validate();
    }

    public created(): void {
        const {email, urlFrom} = this.$route.params;
        if (email) this.email = email.toString();
        if (urlFrom) this.urlFrom = urlFrom.toString();
    }

    public async changePassword() {
        try {
            if (!this.v$.$error) {
                // if ANY fail validation
                this.isBtnLoading = true;
                await UserRepository.changePassword({
                    email: this.email,
                    oldPassword: this.oldPassword.trim(),
                    newPassword: this.password
                })
                    .then(() => {
                        this.toast.success(`Contraseña cambiada con exito`);
                        if (this.urlFrom) window.location.href = this.urlFrom;
                        this.$router.push({name: 'Login'});
                    })
                    .catch((error: AxiosResponse) => {
                        const {error: errorCode} = error.data;
                        if (errorCode === 'INCORRECT_OLD_PASSWORD')
                            return this.toast.error(
                                'La contraseña temporal es incorrecta, por favor revisa tu correo'
                            );

                        if (!errorCode) return this.toast.error('Algo salió mal');
                    })
                    .finally(() => (this.isBtnLoading = false));
            }
        } catch (error: any) {
            this.toast.error('Algo salió mal');
            return error;
        }
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }
}
