import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store/index';
import {routerPermission} from '@/utils/permission-validate';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Register from '@/modules/register/register.vue';
import Dashboard from '@/pages/dashboard/dashboard.vue';
import Profile from '@/pages/profile/profile.vue';
import ForgotPassword from '@/modules/forgot-password/forgot-password.vue';
import ChangePassword from '@/modules/change-password/change-password.vue';
import RecoverPassword from '@/modules/recover-password/recover-password.vue';
import AppsList from '@/modules/applications/list/applications-list.vue';
import AppsCreateEdit from '@/modules/applications/create-edit/create-edit.vue';
import RolesList from '@/modules/roles/list/roles-list.vue';
import RolesCreateEdit from '@/modules/roles/create-edit/roles-create-edit.vue';
import PermissionList from '@/modules/permissions/list/permission-list.vue';
import PermissionsCreateEdit from '@/modules/permissions/create-edit/permissions-create-edit.vue';
import UsersList from '@/modules/users/list/users-list.vue';
import UsersCreateEdit from '@/modules/users/create-edit/users-create-edit.vue';
import UsersAsociateRoles from '@/modules/users/asociate-roles/users-asociate-roles.vue';
import UsersAsociatePermissions from '@/modules/users/asociate-permissions/asociate-permissions.vue';
import RolesAsociatePermissions from '@/modules/roles/asociate-permissions/asociate-permissions.vue';
import ListPermissionsAssociatedRoles from '@/modules/roles/associated-permissions-list/associated-list.vue';
import ListPermissionsAssociatedUser from '@/modules/users/associated-permissions-list/associated-list.vue';
import ListRolesAssociatedUser from '@/modules/users/associated-roles-list/associated-list.vue';
import Forbidden from '@/pages/forbidden/forbidden.vue';
import ReaderPdf from '@/modules/reader-pdf/reader-pdf.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'aplicaciones',
                name: 'app-list',
                component: AppsList,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.OBTENER_APLICACION', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'aplicaciones/crear',
                name: 'app-create',
                component: AppsCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_APLICACION', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'aplicaciones/editar/:application?',
                name: 'app-edit',
                component: AppsCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_APLICACION', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'roles',
                name: 'roles-list',
                component: RolesList,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.OBTENER_ROLES', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'roles/crear',
                name: 'roles-create',
                component: RolesCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_ROLES', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'roles/asociar-permisos',
                name: 'roles-asociate-permissions',
                component: RolesAsociatePermissions,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.ASOCIAR_PERMISOS_ROL', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'roles/editar/:role',
                name: 'roles-edit',
                component: RolesAsociatePermissions,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.ASOCIAR_PERMISOS_ROL', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'permisos',
                name: 'permission-list',
                component: PermissionList,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.OBTENER_PERMISOS', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'permisos/crear',
                name: 'permission-create',
                component: PermissionsCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_PERMISOS', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'permisos/editar/:permission?',
                name: 'permission-edit',
                component: PermissionsCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_PERMISOS', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios',
                name: 'users-list',
                component: UsersList,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.OBTENER_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/crear',
                name: 'users-create',
                component: UsersCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.CREAR_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/editar/:userId?',
                name: 'users-edit',
                component: UsersCreateEdit,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.EDITAR_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/asociar-roles',
                name: 'users-asociate-roles',
                component: UsersAsociateRoles,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.ASOCIAR_ROLES_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/rol-asociado/:user?',
                name: 'users-asociate-roles-edit',
                component: UsersAsociateRoles,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.ASOCIAR_ROLES_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/asociar-permisos',
                name: 'users-asociate-permissions',
                component: UsersAsociatePermissions,
                beforeEnter: (to, from, next) => {
                    routerPermission(
                        'SEGURIDAD.ASOCIAR_PERMISOS_USUARIO',
                        next
                    );
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/permiso-asociado/:user?',
                name: 'users-asociate-permissions-edit',
                component: UsersAsociatePermissions,
                beforeEnter: (to, from, next) => {
                    routerPermission(
                        'SEGURIDAD.ASOCIAR_PERMISOS_USUARIO',
                        next
                    );
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/permisos-asociados',
                name: 'users-associated-permissions',
                component: ListPermissionsAssociatedUser,
                beforeEnter: (to, from, next) => {
                    routerPermission(
                        'SEGURIDAD.ASOCIAR_PERMISOS_USUARIO',
                        next
                    );
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'usuarios/roles-asociados',
                name: 'users-associated-roles',
                component: ListRolesAssociatedUser,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.ASOCIAR_ROLES_USUARIO', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'guia',
                name: 'guide',
                component: ReaderPdf,
                beforeEnter: (to, from, next) => {
                    routerPermission('SEGURIDAD.GUIA', next);
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/change-password',
                name: 'ChangePassword',
                component: ChangePassword,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: RecoverPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/prohibido',
        name: 'Forbidden',
        component: Forbidden,
        meta: {
            requiresUnauth: false
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.getters.token) {
        next('/login');
    }
    next();
});

export default router;
