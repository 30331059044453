import {IUser} from '@/interfaces/user';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import {Options, Vue} from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, helpers} from '@vuelidate/validators';

@Options({
    name: 'apps-create-edit',
    validations: {
        applicationName: {
            required: helpers.withMessage(
                'Ingrese un nombre de aplicación',
                required
            )
        },
        applicationCode: {
            required: helpers.withMessage(
                'Ingrese un ID de aplicación',
                required
            )
        }
    },
    mounted() {
        this.v$.$validate();
    }
})
export default class ApplicationsList extends Vue {
    public applicationName: string = '';
    public applicationCode: string = '';
    isLoading: boolean = false;
    public editMode: boolean = false;
    private toast = useToast();
    v$: any = useVuelidate();

    public created() {
        if (this.$route.params.application?.length > 0) {
            this.editMode = true;
            const {application, name} = this.$route.params;
            if (!application || !name) {
                this.$router.push({name: 'app-list'});
            }
            this.getApplication(application?.toString(), name?.toString());
        }
    }
    get user(): IUser {
        return this.$store.getters.user;
    }

    saveApplication() {
        this.v$.$validate();
        if (!this.v$.$error) {
            if (this.editMode == false) {
                this.createApplication();
            } else {
                this.updateApplication();
            }
        } else {
            this.toast.error(' Por favor, complete este formulario');
        }
    }

    async createApplication() {
        try {
            const data = {
                id: this.applicationCode.toUpperCase(),
                name: this.applicationName.toUpperCase()
            };
            this.isLoading = true;
            await ApplicationRepository.saveApplication(data)
                .then(() => {
                    this.toast.success('Aplicación creada correctamente');
                    this.applicationName = '';
                    this.applicationCode = '';
                })
                .catch((err: any) => {
                    const {data, status} = err;
                    if (status === 422) {
                        if (data?.error === 'validation.unique') {
                            this.toast.error(
                                'El ID de la aplicación, ya existe'
                            );
                            this.applicationCode = '';
                            this.applicationName = '';
                            return;
                        }
                    }

                    if(status=== 500)this.toast.error('Error interno en el servidor');

                    if (!status){
                        this.toast.error(
                            `${err}`
                        );
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error) {
            this.toast.error('Error inesperado en la aplicación');
        }
    }

    getApplication(applicationId: string, name: string) {
        this.applicationCode = applicationId;
        this.applicationName = name;
    }

    async updateApplication() {
        try {
            const data = {
                id: this.applicationCode,
                name: this.applicationName
            };

            await ApplicationRepository.updateApplication(
                this.applicationCode,
                data
            ).then(() => {
                this.applicationCode = '';
                this.applicationName = '';
                this.toast.success('Registro actualizado correctamente');
            }).catch((err: any) => {
                const {data, status} = err;
                if (status === 422) {
                    if (data?.error === 'validation.unique') {
                        this.toast.error(
                            'El ID de la aplicación, ya existe'
                        );
                        this.applicationCode = '';
                        this.applicationName = '';
                        return;
                    }
                }

                if(status=== 500)this.toast.error('Error interno en el servidor');

                if (!status){
                    this.toast.error(
                        `${err}`
                    );
                    return;
                }
            });
        } catch (error) {
            this.toast.error('Error inesperado al actualizar registro');
        }
    }

    goToAppsList() {
        this.$router.push({name: 'app-list'});
    }
}
