import {IUser} from '@/interfaces/user';
import {IUser as userModel} from '@/models/user.model';
import UserRepository from '@/repositories/UsersRepository';
import {Options, Vue} from 'vue-class-component';
import {IUserPaginate} from '@/models/user.paginate.model';
import {validate} from '@/utils/permission-validate';
import {useToast} from 'vue-toastification';
import {AxiosResponse} from 'axios';

@Options({
    name: 'roles-list',
    mounted() {
        this.getResults();
    }
})
export default class UsersList extends Vue {
    public users: IUserPaginate[];
    page = 1;
    records = 0;
    perPage = 10;
    loading: boolean = false;
    criteria: string = '';
    optionFilter: number = 1;
    optionPerPage: number[] = [5, 10, 15, 20];
    optionsStatusFilter: any[] = [
        {
            name: 'Activos',
            value: 1
        },
        {
            name: 'Inactivos',
            value: 0
        }
    ];
    optionsPaginate = {
        texts: {
            count: `Mostrando {from} a {to} de {count} registros | {count} registros | Un registro`
        }
    };

    private toast = useToast();

    public created(): void {
        //this.getUsersList();
    }

    get user(): IUser {
        return this.$store.getters.user;
    }

    async getResults() {
        this.loading = true;
        await UserRepository.getPaginated(
            this.page,
            this.perPage,
            Boolean(this.optionFilter),
            this.criteria ? this.criteria : ''
        )
            .then((response: AxiosResponse) => {
                const {data} = response;
                this.records = data.total;
                this.users = data.data;
            })
            .catch((error: AxiosResponse) => {
                const {status} = error;

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => (this.loading = false));
    }

    async changeUserStatus(userId: number, status: boolean) {
        try {
            const data = {
                status: !status
            };
            await UserRepository.changeUserStatus(userId, data)
                .then(() => {
                    this.toast.success(
                        `Usuario ${
                            status ? 'eliminado' : 'activado'
                        } con éxito.`
                    );
                })
                .catch(() => {
                    this.toast.error(
                        `Ocurrió un error, por favor intente nuevamente.`
                    );
                })
                .finally(async () => {
                    await this.getResults();
                });
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    goToCreateUser() {
        this.$router.push({name: 'users-create'});
    }

    async editUser(user: userModel) {
        const {id, created_at, name, email} = user;
        this.$router.push({
            name: 'users-edit',
            params: {
                userId: id,
                name,
                email
            }
        });
    }

    async cleanCliteria() {
        this.criteria = '';
        await this.filterStatusApp();
    }

    async filterStatusApp() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getResults();
    }

    async rowsPerPage() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getResults();
    }

    isValidPermission(permission: string) {
        return validate(permission);
    }
}
