import {Options, Vue} from 'vue-class-component';
import {useRouter} from 'vue-router';
import {useToast} from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import {required, email, helpers} from '@vuelidate/validators';
import UserRepository from '@/repositories/UserRepository';
import Button from '@/components/button/button.vue';
import {AxiosError, AxiosResponse} from 'axios';

@Options({
    name: 'forgot-password',
    components: {
        'app-button': Button
    },
    validations() {
        return {
            userEmail: {
                required: helpers.withMessage(
                    'Ingrese un correo electrónico',
                    required
                ),
                email: helpers.withMessage(
                    'Ingrese un correo electrónico válido',
                    email
                )
            }
        };
    }
})
export default class ForgotPassword extends Vue {
    private appElement: HTMLElement | null = null;
    public userEmail: string = '';
    public urlFrom: string = '';
    public isBtnLoading: boolean = false;
    v$: any = useVuelidate();
    router = useRouter();
    toast = useToast();

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
        this.v$.$validate();
    }

    public created(): void {
        const {urlFrom} = this.$route.query;
        if (urlFrom) this.urlFrom = decodeURI(urlFrom.toString());
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async sendTemporalPassword(): Promise<void> {
        try {
            if (!this.v$.$error) {
                // if ANY fail validation
                this.isBtnLoading = true;
                await UserRepository.resetPassword({
                    email: this.userEmail
                })
                    .then(() => {
                        this.router
                            .push({
                                name: 'RecoverPassword',
                                params: {
                                    email: this.userEmail,
                                    urlFrom: this.urlFrom
                                }
                            })
                            .then(() => {
                                return this.toast.info(
                                    `Se ha enviado un nuevo mensaje al correo ${this.userEmail} con la nueva contraseña, temporal recuerda cambiarla.`
                                );
                            });
                    })
                    .catch((error: AxiosResponse) => {
                        
                         const {email} = error.data;
                         if (email)
                            return this.toast.error(
                                'El correo electrónico seleccionado no es válido'
                        );

                        if (!email) return this.toast.error('Algo salió mal');
                    })
                    .finally(() => (this.isBtnLoading = false));
            }
        } catch (error: any) {
            this.toast.error('Algo salió mal');
            return error;
        }
    }
}
