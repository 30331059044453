import {Options, Vue} from 'vue-class-component';
import {loginByAuth, loginByGoogle, loginByFacebook} from '@/repositories/auth';
import Checkbox from '@/components/checkbox/checkbox.vue';
import Input from '@/components/input/input.vue';
import Button from '@/components/button/button.vue';
import AuthRepository from '@/repositories/AuthRepository';
import {useToast} from 'vue-toastification';
import Repository from '@/repositories/Repository';
import {AxiosError} from 'axios';

@Options({
    components: {
        'app-checkbox': Checkbox,
        'app-input': Input,
        'app-button': Button
    }
})
export default class Login extends Vue {
    private appElement: HTMLElement | null = null;
    public email: string = '';
    public password: string = '';
    public rememberMe: boolean = false;
    public isAuthLoading: boolean = false;
    public isFacebookLoading: boolean = false;
    public isGoogleLoading: boolean = false;
    showPassword: boolean = false;
    private toast = useToast();

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async loginByAuth(): Promise<void> {
        try {
            this.isAuthLoading = true;
            const data = {
                email: this.email,
                password: this.password,
                applicationId: 'SEGURIDAD'
            };
            AuthRepository.authenticate(data)
                .then((response) => {
                    this.$store.dispatch('login', response.data.access_token);
                    this.$store.dispatch('user', response.data.user);
                    this.$store.dispatch(
                        'permissions',
                        response.data.permissions
                    );

                    this.toast.success('Inicio de sesión exitoso');
                    this.$router.replace('/');
                    this.isAuthLoading = false;
                })
                .catch(({data, status}) => {
                    if (status === 401 && data.error) {
                        this.toast.error(
                            'Correo y/o contraseña incorrectos, intente nuevamente.'
                        );
                    }

                    if (
                        status === 409 &&
                        data?.error === 'USER_MUST_CHANGE_PASSWORD'
                    ) {
                        this.$router
                            .push({
                                name: 'RecoverPassword',
                                params: {
                                    email: this.email
                                }
                            })
                            .then(() => {
                                this.toast.info(
                                    `Debes cambiar tu contraseña, antes de poder iniciar sesion, por favor revisa tu correo y encontraras una contraseña temporal la cual debes utilizar aquí`
                                );
                            });
                    }
                })
                .finally(() => {
                    this.isAuthLoading = false;
                });
        } catch (error: any) {
            console.log(error.data.error);
            this.toast.error(error.message);
            this.isAuthLoading = false;
        }
    }

    public async loginByFacebook(): Promise<void> {
        try {
            this.isFacebookLoading = true;
            const token = await loginByFacebook();
            this.$store.dispatch('login', token);
            this.isFacebookLoading = false;
        } catch (error: any) {
            this.toast.error(error.message);
            this.isFacebookLoading = false;
        }
    }

    public async loginByGoogle(): Promise<void> {
        try {
            this.isGoogleLoading = true;
            const token = await loginByGoogle();
            this.$store.dispatch('login', token);
            this.isGoogleLoading = false;
        } catch (error: any) {
            this.toast.error(error.message);
            this.isGoogleLoading = false;
        }
    }
}
