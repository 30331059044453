import {IUser} from '@/interfaces/user';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import {Options, Vue} from 'vue-class-component';
import {IApplication} from '@/models/application.model';
import {validate} from '@/utils/permission-validate';
import {useToast} from 'vue-toastification';
import {AxiosResponse} from 'axios';

@Options({
    name: 'apps-list'
})
export default class ApplicationsList extends Vue {
    applications: [] = [];
    loading: boolean = false;
    btnLoading: boolean = false;
    page: number = 1;
    records: number = 0;
    perPage: number = 10;
    criteria: string = '';
    optionFilter: number = 1;
    optionPerPage: number[] = [5, 10, 15, 20];
    optionsStatusFilter: any[] = [
        {
            name: 'Activas',
            value: 1
        },
        {
            name: 'Inactivas',
            value: 0
        }
    ];
    optionsPaginate = {
        texts: {
            count: `Mostrando {from} a {to} de {count} registros | {count} registros | Un registro`
        }
    };
    private toast = useToast();

    // Declare created lifecycle hook
    created() {
        this.getApplications();
    }

    get user(): IUser {
        return this.$store.getters.user;
    }

    async getApplications() {
        this.loading = true;
        await ApplicationRepository.getPaginated(
            this.page,
            this.perPage,
            Boolean(this.optionFilter),
            this.criteria ? this.criteria : ''
        )
            .then((response: AxiosResponse) => {
                const {data} = response;
                this.records = data.total;
                this.applications = data.data;
            })
            .catch((error: AxiosResponse) => {
                const {status} = error;

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => (this.loading = false));
    }

    isValidPermission(permission: string) {
        return validate(permission);
    }

    async editApp(application: IApplication) {
        this.$router.push({
            name: 'app-edit',
            params: {
                application: application.id,
                name: application.name
            }
        });
    }

    async changeAppStatus(appId: number, status: boolean) {
        try {
            this.loading = true;
            const data = {
                status: !status
            };
            await ApplicationRepository.changeApplicationStatus(appId, data)
                .then(() => {
                    this.toast.success(
                        `Aplicación ${
                            status ? 'eliminada' : 'activada'
                        } con éxito.`
                    );
                })
                .catch(() => {
                    this.toast.error(
                        `Ocurrió un error, por favor intente nuevamente.`
                    );
                })
                .finally(async () => {
                    await this.getApplications();
                });
        } catch (error: any) {
            return;
        }
    }

    async cleanCliteria() {
        this.criteria = '';
        await this.filterStatusApp();
    }

    async filterStatusApp() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getApplications();
    }

    async rowsPerPage() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getApplications();
    }

    goToCreateApp() {
        this.$router.push({name: 'app-create'});
    }
}
