import {IUser} from '@/interfaces/user';
import UserRepository from '@/repositories/UsersRepository';
import {Options, Vue} from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import {
    required,
    email,
    minLength,
    helpers,
    sameAs,
    maxLength
} from '@vuelidate/validators';
import {useToast} from 'vue-toastification';
import {passwordValid} from '@/utils/password-validations';
@Options({
    name: 'roles-create-edit',
    validations() {
        return {
            userName: {
                required: helpers.withMessage(
                    'Ingrese el nombre completo',
                    required
                )
            },
            userEmail: {
                required: helpers.withMessage(
                    'Ingrese un correo electrónico',
                    required
                ),
                email: helpers.withMessage(
                    'Ingrese un correo electrónico válido',
                    email
                )
            },
            userPassword: {
                required: helpers.withMessage(
                    'Ingrese una contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            },
            userPasswordConfirm: {
                required: helpers.withMessage(
                    'Ingrese confirmación de contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                sameAsPassword: helpers.withMessage(
                    'La confirmación de la contraseña debe ser igual a la otra contraseña',
                    sameAs(this.userPassword)
                ),
                passwordValid
            }
        };
    },
    mounted() {
        this.v$.$validate();
    }
})
export default class UsersCreateEdit extends Vue {
    private toast = useToast();
    isLoading: boolean = false;
    editMode: boolean = false;
    userName: string = '';
    userEmail: string = '';
    userPassword: string = '';
    userPasswordConfirm: string = '';
    userId: number;
    showUserPassword: boolean = false;
    showUserPasswordConfirm: boolean = false;

    v$: any = useVuelidate();

    get user(): IUser {
        return this.$store.getters.user;
    }

    // Declare created lifecycle hook
    created() {
        const user = parseInt(this.$route.params.userId?.toString());
        if (user > 0) {
            const {name, email} = this.$route.params;
            this.editMode = true;
            this.userId = user;
            if (!name && !email) {
                this.$router.push({name: 'users-list'});
            }

            this.setForm(name?.toString(), email?.toString());
        }
    }

    setForm(name: string, email: string) {
        this.userName = name;
        this.userEmail = email;
    }

    async saveUser() {
        this.v$.$validate();
        if (!this.v$.userName.$invalid && !this.v$.userEmail.$invalid) {
            if (this.editMode) {
                this.updateUser();
            }
        }

        if (!this.v$.$error) {
            // if ANY fail validation
            if (this.editMode == false) {
                this.createUser();
            }
        }
    }

    async createUser() {
        try {
            const data = {
                name: this.userName,
                email: this.userEmail,
                password: this.userPassword
            };
            this.isLoading = true;
            await UserRepository.saveUser(data)
                .then(() => {
                    this.toast.success('Usuario guardado exitosamente');
                    this.userName = '';
                    this.userEmail = '';
                    this.userPassword = '';
                    this.userPasswordConfirm = '';
                })
                .catch((err) => {
                    const {data, status} = err;
                    if (status === 422) {
                        if (data.error === 'EMAIL_ALREADY_EXISTS') {
                            this.toast.error('El email ya ha sido registrado');
                            this.userEmail = '';
                            return;
                        }
                    }
                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error: any) {
            this.toast.error('Error inesperado en la aplicación');
            this.isLoading = false;
        }
    }

    async updateUser() {
        try {
            const data = {
                name: this.userName,
                email: this.userEmail
            };
            this.isLoading = true;
            await UserRepository.updateUser(this.userId, data)
                .then(() => {
                    this.toast.success('Usuario actualizado exitosamente');
                    this.userName = '';
                    this.userEmail = '';
                })
                .catch((err) => {
                    const {data, status} = err;
                    if (status === 422) {
                        if (data.error === 'EMAIL_ALREADY_EXISTS') {
                            this.toast.error('El email ya ha sido registrado');
                            this.userEmail = '';
                            return;
                        }
                    }
                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error) {
            this.toast.error('Error al actualizar el usuario');
            this.isLoading = false;
        }
    }

    goToRolesList() {
        this.$router.push({name: 'users-list'});
    }

    validations() {
        return {
            userPasswordConfirm: {
                sameAsPassword: sameAs(this.userPassword)
            }
        };
    }
}
