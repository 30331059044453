import {IUser} from '@/interfaces/user';
import router from '@/router/index';

export default {
    login: (context: any, payload: string): any => {
        context.commit('setToken', payload);
    },
    user: (context: any, payload: IUser): any => {
        context.commit('setUser', payload);
    },
    permissions: (context: any, payload: []): any => {
        context.commit('setPermissions', payload);
    },
    logout: (context: any) => {
        context.commit('setToken', null);
        context.commit('setUser', null);
        context.commit('setApplicationCode', null);
        router.replace('/login');
    },
    applicationCode: (context: any, payload: string) => {
        context.commit('setApplicationCode', payload);
    }
};
