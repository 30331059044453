import Repository from './Repository';

const resource = 'roles';

export default {
    getRoles() {
        return Repository.get(`${resource}`);
    },
    getPaginated(
        page: number,
        limit: number,
        status: boolean = true,
        criteria: string = ''
    ) {
        return Repository.get(
            `${resource}/paginate?page=${page}&limit=${limit}${`&status=${status}`}${
                criteria ? `&criteria=${criteria}` : ''
            }`
        );
    },
    saveRole(data: any) {
        return Repository.post(`${resource}`, data);
    },
    updateRole(roleId: number, data: any) {
        return Repository.put(`${resource}/${roleId}`, data);
    },
    changeRoleStatus(roleId: number, data: any) {
        return Repository.post(`${resource}/${roleId}/change-status`, data);
    },
    assignPermissions(data: any) {
        return Repository.post(`${resource}/permissions`, data);
    },
    associatedPermissions(roleId: number) {
        return Repository.get(`${resource}/${roleId}/permissions`);
    }
};
