import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import {IPermission} from '@/models/permission.model';
import {IRole} from '@/models/role.model';
import PermissionsRepository from '@/repositories/PermissionsRepository';
import RolesRepository from '@/repositories/RolesRepository';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, minLength, helpers} from '@vuelidate/validators';
import {IApplication} from '@/models/application.model';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import {validate} from '@/utils/permission-validate';

@Options({
    name: 'asociate-permissions',
    validations() {
        return {
            roleName: {
                required: helpers.withMessage(
                    'Ingrese un nombre para el rol',
                    required
                )
            },
            selectedPermissions: {required, minLength: minLength(1)}
        };
    },
    mounted() {
        const role = parseInt(this.$route.params.role?.toString());
        this.getApplications();
        if (role > 0) {
            this.roleId = role;
            //this.editMode = true;
            this.setData(role);
        } else {
            if (this.applicationCode) {
                this.getResults();
            }
            this.v$.$validate();
        }
    }
})
export default class UsersAsociatePermissions extends Vue {
    roleName: string = '';
    roles: IRole;
    applicationId: string = '';
    applications: IApplication[] = [];
    permissions: IPermission[] = [];
    permission: IPermission;
    // permission: string;
    selectedPermissions: IPermission[] = [];
    roleId: number = 0;
    toast = useToast();
    v$: any = useVuelidate();
    page = 1;
    records = 0;
    perPage = 10;
    loading: boolean = false;
    editMode: boolean = false;
    isBtnLoading: boolean = false;

    get user(): IUser {
        return this.$store.getters.user;
    }

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    async getResults() {
        this.loading = true;
        const {data} = await RolesRepository.getRoles();
        this.roles = data;
        this.loading = false;
    }

    setData(roleId: number): any {
        const {name} = this.$route.params;
        if (!name) {
            this.$router.push({name: 'roles-list'});
        }

        this.roleName = name?.toString();

        this.roles = {
            id: roleId,
            name: name?.toString(),
            created_at: null,
            updated_at: null,
            guard_name: null
        };
        this.associatedPermissions(roleId);
    }

    editRole() {
        this.editMode = true;
    }

    async associatedPermissions(roleId: number) {
        this.isBtnLoading = true;
        await RolesRepository.associatedPermissions(roleId)
            .then((success) => {
                this.selectedPermissions = success.data;
            })
            .catch((err) => {
                const {data, status} = err;

                if (status === 401) {
                    this.toast.info(
                        `Su sesión ha caducado. será redirigido a la página de inicio de sesión`
                    );
                    return;
                }

                if (status === 422) {
                    if (data.error === 'NAME_ALREADY_EXISTS') {
                        this.toast.error('El nombre del rol ya existe');
                        this.roleName = '';
                        return;
                    }
                }

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${err}`);
                    return;
                }
            })
            .finally(() => {
                this.isBtnLoading = false;
            });
    }

    async getPermissionList() {
        this.isBtnLoading = true;
        await PermissionsRepository.getPermission(this.applicationId)
            .then((Success) => {
                this.permissions = Success.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.isBtnLoading = false;
            });
    }

    async getApplications() {
        await ApplicationRepository.getApplication()
            .then((Success) => {
                this.applications = Success.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    addPermisison() {
        // const currentPermission: IPermission = this.permissions.find(
        //     (permission) => permission.name === this.permission
        // );

        const permissionElement = this.selectedPermissions.some(
            (permission) => permission.id === this.permission.id
        );
        if (permissionElement) {
            this.toast.error('El permiso ya existe, para el rol seleccionado');
        } else {
            this.selectedPermissions.push(this.permission);
            this.toast.info('Permiso agregado a la lista, recuerde guardar');
        }
    }

    removePermission(permissionIndex: number) {
        this.selectedPermissions.splice(permissionIndex, 1);
    }

    goToRolesList() {
        this.$router.push({name: 'roles-list'});
    }

    saveAsociation() {
        this.v$.$validate();

        if (this.editMode) {
            return this.updateRole();
        }

        return this.createAssociation();
        // if (!this.v$.$error) {

        //  }
        //  else {
        //     if (this.v$.selectedPermissions.$invalid) {
        //         this.toast.error('Seleccione al menos un permiso');
        //     }
        // }
    }

    async createAssociation() {
        try {
            this.isBtnLoading = true;
            const data = {
                roleId: this.roles.id,
                permissions: this.selectedPermissions.map((permission) => {
                    return permission.id;
                })
            };

            await RolesRepository.assignPermissions(data)
                .then((success) => {
                    this.isBtnLoading = false;
                    this.toast.success('Permisos actualizados con éxito');
                })
                .catch((err: any) => {
                    this.toast.error('Error inesperado');
                    this.isBtnLoading = false;
                });
        } catch (error) {
            this.toast.error('Error inesperado en la aplicación');
        }
    }

    async updateRole() {
        try {
            this.isBtnLoading = true;
            const data = {
                name: `${this.roleName.toUpperCase()}`
            };
            //this.isLoading = true;
            await RolesRepository.updateRole(this.roleId, data)
                .then((response) => {
                    this.toast.success('Rol actualizado correctamente');
                })
                .catch((err) => {
                    const {data, status} = err;

                    if (status === 401) {
                        this.toast.info(
                            `Su sesión ha caducado. será redirigido a la página de inicio de sesión`
                        );
                        return;
                    }

                    if (status === 422) {
                        if (data.error === 'NAME_ALREADY_EXISTS') {
                            this.toast.error('El nombre del rol ya existe');
                            return;
                        }
                    }

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isBtnLoading = false;
                    this.editMode = false;
                });
        } catch (error) {
            this.toast.error('Error inesperado al actualizar rol');
            this.isBtnLoading = false;
            //this.isLoading = false;
        }
    }

    async updateAssociation() {
        try {
            const data = {
                roleId: this.roles.id,
                permissions: this.selectedPermissions.map((permission) => {
                    return permission.id;
                })
            };

            await RolesRepository.updateRole(this.roles.id, data);
        } catch (error) {
            console.log(error);
        }
    }

    isValidPermission(permission: string) {
        return validate(permission);
    }
}
