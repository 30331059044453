import Repository from './Repository';

const resource = 'permissions';

export default {
    getPermission(applicationId: string) {
        return Repository.get(`${resource}?applicationId=${applicationId}`);
    },
    getPaginated(
        page: number,
        limit: number,
        status: boolean = true,
        applicationId: string = '',
        criteria: string = ''
    ) {
        return Repository.get(
            `${resource}/paginate?page=${page}&limit=${limit}${`&status=${status}`}${
                applicationId ? `&applicationId=${applicationId}` : ''
            }${criteria ? `&criteria=${criteria}` : ''}`
        );
    },
    savePermission(data: any) {
        return Repository.post(`${resource}`, data);
    },
    updatePermission(permissionId: number, data: any) {
        return Repository.put(`${resource}/${permissionId}`, data);
    },
    changePermissionStatus(permissionId: number, data: any) {
        return Repository.post(
            `${resource}/${permissionId}/change-status`,
            data
        );
    }
};
