import {IUser} from '@/interfaces/user';
import {IMenuOption} from '@/interfaces/MenuOption';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem
    }
})
export default class MenuSidebar extends Vue {
    public menu = MENU;
    public action = ACTION;
    get user(): IUser {
        return this.$store.getters.user;
    }
}

export const MENU = [
    // {
    //     name: 'labels.dashboard',
    //     path: '/'
    // },
    {
        name: 'Aplicaciones',
        path: '/aplicaciones',
        action: 'SEGURIDAD.OBTENER_APLICACION'
    },
    {
        name: 'Permisos',
        path: '/permisos',
        action: 'SEGURIDAD.OBTENER_PERMISOS'
    },
    {
        name: 'Roles',
        path: '/roles',
        action: 'SEGURIDAD.OBTENER_ROLES'
        // children: [
        //     {
        //         name: 'Gestion',
        //         path: '/roles',
        //         action: 'OBTENER_ROLES'
        //     },
        //     {
        //         name: 'Permisos asociados',
        //         path: '/roles/permisos-asociados',
        //         action: 'ASOCIAR_PERMISOS_ROL'
        //     }
        // ]
    },

    {
        name: 'Usuarios',
        children: [
            {
                name: 'Todos los usuarios',
                path: '/usuarios',
                action: 'SEGURIDAD.OBTENER_USUARIO'
            },
            {
                name: 'Permisos asociados',
                path: '/usuarios/permisos-asociados',
                action: 'SEGURIDAD.ASOCIAR_PERMISOS_USUARIO'
            },
            {
                name: 'Roles asociados',
                path: '/usuarios/roles-asociados',
                action: 'SEGURIDAD.ASOCIAR_ROLES_USUARIO'
            }
        ]
    },
    {
        name: 'Guia',
        path: '/guia',
        action: 'SEGURIDAD.GUIA'
    },
];

export const ACTION = {
    name: 'Cambiar contraseña',
    path: '/change-password',
    action: 'SEGURIDAD.OBTENER_ROLES'
};
