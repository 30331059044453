import {IUser} from '@/interfaces/user';
import RolesRepository from '@/repositories/RolesRepository';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import {Options, Vue} from 'vue-class-component';
import {IApplication} from '@/models/application.model';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, helpers} from '@vuelidate/validators';
import {useStore} from 'vuex';

@Options({
    name: 'roles-create-edit',
    mounted() {
        this.v$.$validate();
    },
    validations: {
        roleName: {
            required: helpers.withMessage(
                'Ingrese un nombre para el rol',
                required
            )
        }
    }
})
export default class RolesList extends Vue {
    editMode: boolean = false;
    isLoading: boolean = false;
    roleName: string = '';
    roleId: number;
    applications: IApplication[] = [];
    private toast = useToast();
    v$: any = useVuelidate();
    store = useStore();

    get user(): IUser {
        return this.store.getters.user;
    }

    get applicationCode(): string {
        return this.store.getters.applicationCode;
    }

    // Declare created lifecycle hook
    created() {
        this.getApplications();
        const role = parseInt(this.$route.params.role?.toString());

        if (role > 0) {
            const {name} = this.$route.params;
            this.editMode = true;
            this.roleId = role;
            if (!name) {
                this.$router.push({name: 'roles-list'});
            }

            this.setName(name?.toString());
        }
    }

    async getApplications() {
        return await ApplicationRepository.getApplication()
            .then((Success) => {
                this.applications = Success.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    setName(role: string) {
        this.roleName = role;
    }

    saveRole() {
        this.v$.$validate();
        if (!this.v$.$error) {
            if (this.editMode == false) {
                this.createRole();
            } else {
                this.updateRole();
            }
        } else {
            this.toast.error(' Por favor, complete este formulario');
        }
    }

    async createRole() {
        try {
            const data = {
                name: `${this.roleName.toUpperCase()}`
            };
            this.isLoading = true;
            await RolesRepository.saveRole(data)
                .then(() => {
                    this.toast.success('Rol creado correctamente');
                    this.roleName = '';
                })
                .catch((err) => {
                    const {data, status} = err;

                    if (status === 401) {
                        this.toast.info(
                            `Su sesión ha caducado. será redirigido a la página de inicio de sesión`
                        );
                        return;
                    }
                    
                    if (status === 422) {
                        if (data.error === 'NAME_ALREADY_EXISTS') {
                            this.toast.error('El nombre del rol ya existe');
                            this.roleName = '';
                            return;
                        }
                    }

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error) {
            this.toast.error('Error inesperado en la aplicación');
            this.isLoading = false;
        }
    }

    async updateRole() {
        try {
            const data = {
                name: `${this.roleName.toUpperCase()}`
            };
            this.isLoading = true;
            await RolesRepository.updateRole(this.roleId, data)
                .then(() => {
                    this.roleName = '';
                    this.toast.success('Rol actualizado correctamente');
                })
                .catch((err) => {
                    const {data, status} = err;

                    if (status === 401) {
                        this.toast.info(
                            `Su sesión ha caducado. será redirigido a la página de inicio de sesión`
                        );
                        return;
                    }

                    if (status === 422) {
                        if (data.error === 'NAME_ALREADY_EXISTS') {
                            this.toast.error('El nombre del rol ya existe');
                            this.roleName = '';
                            return;
                        }
                    }

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error: any) {
            this.toast.error('Error inesperado al actualizar rol');
            this.isLoading = false;
        }
    }

    goToRolesList() {
        this.$router.push({name: 'roles-list'});
    }
}
