import {ref, onMounted} from 'vue';
import WebViewer, {WebViewerInstance} from '@pdftron/webviewer';
import {join} from 'path';

export default {
    name: 'WebViewer',
    props: {initialDoc: {type: String}},
    setup(props: any) {
        const viewer = ref(null);
        onMounted(() => {
            const path = `/webviewer`;
            //const file = join('../../assets/pdf','intrucciones.pdf');
            WebViewer(
                {
                    path,
                    initialDoc: props.initialDoc,
                    disabledElements: [
                        'viewControlsButton',
                        'panToolButton',
                        'selectToolButton',
                        'printButton',
                        'themeChangeButton',
                        'languageButton',
                        'thumbDelete',
                        'thumbRotateClockwise',
                        'pageManipulationOverlayButton',
                        'outlinesPanelButton',
                        'documentControl',
                        'toolsHeader'
                    ]
                },
                viewer.value
            ).then((instance: WebViewerInstance) => {
                const {
                    setLanguage,
                    setHeaderItems,
                    disableElements,
                    disableFeatures,
                    Feature
                } = instance.UI;

                setLanguage('es');
                disableFeatures([Feature.Ribbons]);
                disableFeatures([Feature.TextSelection]);
                disableFeatures([Feature.Annotations]);
                disableFeatures([Feature.ThumbnailReordering]);
            });
        });
        return {
            viewer
        };
    }
};
