import axiosInstance from '@/utils/axios';
// import SecureLS from 'secure-ls';
// const ls = new SecureLS({isCompression: false});

const Repository = {
    removeHeaders() {
        axiosInstance.defaults.headers.common = {};
    },
    get(resource: string) {
        return axiosInstance.get(resource);
    },
    post(resource: string, data: any) {
        return axiosInstance.post(resource, data);
    },
    put(resource: string, data: any) {
        return axiosInstance.put(resource, data);
    },
    delete(resource: string) {
        return axiosInstance.delete(resource);
    },
    patch(resource: any, data: any) {
        return axiosInstance.patch(resource, data);
    },
    postFile(resource: string, file: any) {
        const formData = new FormData();
        const headers = {'Content-Type': 'multipart/form-data'};
        formData.append('attachment', file);
        return axiosInstance.post(resource, formData, {headers});
    }
};

export default Repository;
