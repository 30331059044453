import Repository from './Repository';

const resource = 'applications';

export default {
    getApplication() {
        return Repository.get(`${resource}`);
    },
    getPaginated(
        page: number,
        limit: number,
        status: boolean = true,
        criteria: string = ''
    ) {
        return Repository.get(
            `${resource}/paginate?page=${page}&limit=${limit}${`&status=${status}`}${
                criteria ? `&criteria=${criteria}` : ''
            }`
        );
    },
    saveApplication(data: any) {
        return Repository.post(`${resource}`, data);
    },
    updateApplication(applicationId: string, data: any) {
        return Repository.put(`${resource}/${applicationId}`, data);
    },
    changeApplicationStatus(applicationId: number, data: any) {
        return Repository.post(
            `${resource}/${applicationId}/change-status`,
            data
        );
    }
};
