import {IUser} from '@/interfaces/user';
import UserRepository from '@/repositories/UserRepository';
import {Options, Vue} from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import {
    required,
    minLength,
    helpers,
    sameAs,
    maxLength
} from '@vuelidate/validators';
import {useToast} from 'vue-toastification';
import {passwordValid} from '@/utils/password-validations';

@Options({
    name: 'roles-create-edit',
    validations() {
        return {
            userOldPassword: {
                required: helpers.withMessage(
                    'Ingrese la contraseña anterior',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            },
            userPassword: {
                required: helpers.withMessage(
                    'Ingrese una nueva contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            },
            userPasswordConfirm: {
                required: helpers.withMessage(
                    'Ingrese la confirmación de la nueva contraseña',
                    required
                ),
                minLength: helpers.withMessage(
                    'Este campo debe tener al menos 8 caracteres',
                    minLength(8)
                ),
                sameAsPassword: helpers.withMessage(
                    'La confirmación de la contraseña debe ser igual a la otra contraseña',
                    sameAs(this.userPassword)
                ),
                maxLength: helpers.withMessage(
                    'Este campo debe ser menor o igual a 16 caracteres',
                    maxLength(16)
                ),
                passwordValid
            }
        };
    },
    mounted() {
        this.v$.$validate();
    }
})
export default class UsersCreateEdit extends Vue {
    private toast = useToast();
    isLoading: boolean = false;
    editMode: boolean = false;
    userEmail: string = '';
    userOldPassword: string = '';
    userPassword: string = '';
    userPasswordConfirm: string = '';
    userId: number;
    showUserOldPassword:boolean = false;
    showUserPassword:boolean = false;
    showUserPasswordConfirm:boolean = false;

    v$: any = useVuelidate();

    get user(): IUser {
        return this.$store.getters.user;
    }

    setForm(email: string) {
        this.userEmail = email;
    }

    public async changePassword() {
        try {
            if (!this.v$.$error) {
                // if ANY fail validation
                this.isLoading = true;
                const {email} = this.user;
                await UserRepository.changePassword({
                    email: email,
                    oldPassword: this.userOldPassword,
                    newPassword: this.userPassword
                })
                    .then(() => {
                        this.toast.success(`Contraseña cambiada con exito`);
                    }).catch((err) => {
                        const {data, status} = err;
                        if (status === 422) {
                            if (data.error === 'EMAIL_ALREADY_EXISTS') {
                                this.toast.error('El email ya ha sido registrado');
                                this.userEmail = '';
                                return;
                            }
                            if (data.error === 'PASSWORD_IS_TOO_LONG') {
                                this.toast.error('El email ya ha sido registrado');
                                this.userEmail = '';
                                return;
                            }
                        }
                        if (status === 500)
                            this.toast.error('Error interno en el servidor');
    
                        if (!status) {
                            this.toast.error(`${err}`);
                            return;
                        }
                    })
                    .finally(() => (this.isLoading = false));
            }
        } catch (error: any) {
            const {error: errorCode} = error;
            if (errorCode === 'INCORRECT_OLD_PASSWORD')
                this.toast.error('La contraseña anterior es incorrecta');

            if (!errorCode) this.toast.error('Algo salió mal');
            this.isLoading = false;
        }
    }
}
