import {Options, Vue} from 'vue-class-component';
import WebViewer from '@/components/web-viewer/web-viewer.vue';

@Options({
    name: 'ReaderPdf',
    components: {
        'web-viewer': WebViewer
    }
})
export default class ReaderPdf extends Vue {}
