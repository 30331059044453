import {IUser} from '@/interfaces/user';
import PermissionsRepository from '@/repositories/PermissionsRepository';
import {Options, Vue} from 'vue-class-component';
import {IPermission} from '@/models/permission.model';
import {validate} from '@/utils/permission-validate';
import {IApplication} from '@/models/application.model';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import Button from '@/components/button/button.vue';
import {AxiosResponse} from 'axios';
import {useToast} from 'vue-toastification';

@Options({
    name: 'permission-list',
    components: {
        'app-button': Button
    },
    async mounted() {
        await this.getApplications();
        await this.getResults();
    }
})
export default class PermissionList extends Vue {
    permissions: any = [];
    applications: IApplication[] = [];
    applicationId: string = '';
    loading: boolean = false;
    page: number = 1;
    criteria: string = '';
    records: number = 0;
    perPage: number = 10;
    btnLoading: boolean = false;
    optionFilter: number = 1;
    optionPerPage: number[] = [5, 10, 15, 20];
    optionsStatusFilter: any[] = [
        {
            name: 'Activos',
            value: 1
        },
        {
            name: 'Inactivos',
            value: 0
        }
    ];
    optionsPaginate = {
        texts: {
            count: `Mostrando {from} a {to} de {count} registros | {count} registros | Un registro`
        }
    };
    private toast = useToast();

    get user(): IUser {
        return this.$store.getters.user;
    }

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    async getResults() {
        if (this.applicationId) {
            this.loading = true;
            await PermissionsRepository.getPaginated(
                this.page,
                this.perPage,
                Boolean(this.optionFilter),
                this.applicationId,
                this.criteria ? this.criteria : null
            )
                .then((response: AxiosResponse) => {
                    this.permissions = response.data.data;
                    this.records = response.data.total;
                })
                .catch((error: AxiosResponse) => {
                    const {status} = error;

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) this.toast.error(`${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    async cleanCliteria() {
        this.criteria = '';
        await this.filterApp();
    }

    async filterApp() {
        this.btnLoading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getResults();
        this.btnLoading = false;
    }

    async editPermission(permission: IPermission) {
        permission.applicationId;
        this.$router.push({
            name: 'permission-edit',
            params: {
                permission: permission.id,
                name: permission.name,
                applicationId: permission.applicationId
            }
        });
    }

    async getApplications() {
        this.loading = true;
        await ApplicationRepository.getApplication()
            .then(async (response: AxiosResponse) => {
                this.applications = response.data;
                this.applicationId = this.applicationId
                    ? this.applicationId
                    : response.data[0]?.name;
            })
            .catch((error: AxiosResponse) => {
                const {status} = error;

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    async changePermissionStatus(permissionId: number, status: boolean) {
        try {
            this.loading = true;
            const data = {
                status: !status
            };
            await PermissionsRepository.changePermissionStatus(
                permissionId,
                data
            )
                .then(() => {
                    this.toast.success(
                        `Permiso ${
                            status ? 'eliminado' : 'activado'
                        } con éxito.`
                    );
                })
                .catch(() => {
                    this.toast.error(
                        `Ocurrió un error, por favor intente nuevamente.`
                    );
                })
                .finally(async () => {
                    await this.getResults();
                });
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    async rowsPerPage() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getResults();
    }

    goToCreatePermission() {
        this.$router.push({name: 'permission-create'});
    }

    isValidPermission(permission: string) {
        return validate(permission);
    }
}
