import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import Toast, {PluginOptions, POSITION} from 'vue-toastification';
import VueGoodTablePlugin from 'vue-good-table';
import {createI18n} from 'vue-i18n';
import Repository from './repositories/Repository';
import en from './translation/en.json';
import es from './translation/es.json';
import tr from './translation/tr.json';
// import the styles
import './index.scss';
import Pagination from 'v-pagination-3';
import 'vue-toastification/dist/index.css';
import 'vue-good-table/dist/vue-good-table.css';

library.add(faLock, faEnvelope, faFacebook, faGooglePlus);

const options: PluginOptions = {
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
    position: POSITION.TOP_RIGHT
};
const i18n = createI18n({
    locale: 'en',
    messages: {en, es, tr},
    fallbackLocale: 'en'
});

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('pagination', Pagination)
    .use(store)
    .use(router)
    .use(Toast, options)
    .use(i18n)
    .use(VueGoodTablePlugin)
    .mount('#app');
