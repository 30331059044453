import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import {IUserPaginate} from '@/models/user.paginate.model';
import {IApplication} from '@/models/application.model';
import {IRole} from '@/models/role.model';
import {IUser as User} from '@/models/user.model';
import UserRepository from '@/repositories/UsersRepository';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import RolesRepository from '@/repositories/RolesRepository';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, minLength} from '@vuelidate/validators';
import {AxiosResponse} from 'axios';

@Options({
    name: 'asociate-roles',
    validations: {
        selectedUsers: {
            required,
            minLength: minLength(1)
        },
        applicationId: {required},
        selectedRoles: {required, minLength: minLength(1)}
    },
    mounted() {
        this.getRolesList();
        const userId = parseInt(this.$route.params.user?.toString());
        if (userId > 0) {
            this.editMode = true;
            this.setData(userId);
        } else {
            this.getResults();
            this.v$.$validate();
        }
    },
    watch: {
        '$store.state.applicationCode': {
            immediate: true,
            handler() {
                // update locally relevant data
                this.applicationId = this.applicationCode;
                this.getRolesList();
            }
        }
    }
})
export default class UsersAsociateRoles extends Vue {
    users: User[] = [];
    applications: [] = [];
    applicationId: string = '';
    role: IRole;
    roles: [] = [];
    selectedRoles: IRole[] = [];
    selectedUsers: number[] = [];
    private toast = useToast();
    v$: any = useVuelidate();
    page = 1;
    records = 0;
    perPage = 10;
    loading: boolean = false;
    editMode: boolean = false;
    isBtnLoading: boolean = false;

    get user(): IUser {
        return this.$store.getters.user;
    }

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    async getResults() {
        this.loading = true;
        const {data} = await UserRepository.getPaginated(
            this.page,
            this.perPage
        );
        this.records = data.total;
        this.users = data.data;
        this.loading = false;
    }

    setData(userId: number): any {
        this.selectedUsers.push(userId);
        const {created_at, name, email} = this.$route.params;
        if (!name || !email || !created_at) {
            this.$router.push({name: 'users-associated-roles'});
        }
        this.users = [
            {
                id: userId,
                created_at: created_at?.toString(),
                name: name?.toString(),
                email: email?.toString(),
                updated_at: null
            }
        ];
        this.getRolesUser(userId);
    }

    getRolesUser(userId: number) {
        this.loading = true;
        UserRepository.associatedRoles(userId)
            .then((response) => {
                this.selectedRoles = response.data;
            })
            .catch((error: AxiosResponse) => {
                const {status} = error;

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => (this.loading = false));
    }

    getRolesList() {
        this.loading = true;
        RolesRepository.getRoles()
            .then((response) => {
                this.roles = response.data;
            })
            .catch((error: AxiosResponse) => {
                const {status} = error;

                if (status === 500)
                    this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => (this.loading = false));
    }

    addRole() {
        const permissionElement = this.selectedRoles.some(
            (role) => role.id === this.role.id
        );
        if (permissionElement) {
            this.toast.error('Ya existe el elemento');
        } else {
            this.selectedRoles.push(this.role);
        }
    }

    removeRole(permissionIndex: number) {
        this.selectedRoles.splice(permissionIndex, 1);
    }

    goToUsersList() {
        this.$router.push({name: 'users-associated-roles'});
    }

    saveAsociation() {
        this.v$.$validate();
        if (!this.v$.selectedUsers.$invalid && this.editMode) {
            this.createAssociation();
        }
        if (!this.v$.$error && this.editMode === false) {
            this.createAssociation();
        } else {
            if (this.v$.selectedUsers.$invalid) {
                this.toast.error(' Por favor, seleccione un usuario');
            }
            if (this.v$.selectedRoles.$invalid && this.editMode === false) {
                this.toast.error(' Por favor, seleccione un rol');
            }
        }
    }

    uniqueCheck(e: any) {
        this.selectedUsers = [];
        if (e.target.checked) {
            this.selectedUsers = [e.target.value];
        }
    }

    async createAssociation() {
        try {
            this.isBtnLoading = true;
            const data = {
                userId: Number.parseInt(this.selectedUsers[0].toString(), 10),
                roles: this.selectedRoles.map((role) => {
                    return role.id;
                })
            };

            await UserRepository.assignRoles(data)
                .then((response) => {
                    if (this.editMode) {
                        this.toast.success(
                            'Roles asociados, actualizados correctamente'
                        );
                    } else {
                        this.toast.success('Roles asociados correctamente');
                    }
                })
                .catch((error: AxiosResponse) => {
                    const {status} = error;

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${error}`);
                        return;
                    }
                })
                .finally(() => (this.isBtnLoading = false));
        } catch (error) {
            console.log(error);
            this.toast.error('Error inesperado');
            this.isBtnLoading = false;
        }
    }
}
