import store from '@/store/index';
import {NavigationGuardNext} from 'vue-router';

export function validate(permission: string) {
    const permissions = store.getters.permissions;
    return permissions.some((p: any) => p.name === permission);
}

export function routerPermission(
    permission: string,
    next: NavigationGuardNext
) {
    const isValidPermit = validate(permission);
    if (isValidPermit) {
        next();
    } else {
        next('/prohibido');
    }
}
