import {Options, Vue} from 'vue-class-component';
import Messages from './messages/messages.vue';
import Notifications from './notifications/notifications.vue';
import Languages from './languages/languages.vue';
import User from './user/user.vue';
import {IApplication} from '@/models/application.model';
import ApplicationRepository from '@/repositories/ApplicationRepository';

@Options({
    components: {
        'messages-dropdown': Messages,
        'notifications-dropdown': Notifications,
        'languages-dropdown': Languages,
        'user-dropdown': User
    }
})
export default class Header extends Vue {
    private headerElement: HTMLElement | null = null;
    applications: IApplication[] = [];
    applicationId: string = '';

    public async mounted(): Promise<void> {
        this.headerElement = document.getElementById(
            'main-header'
        ) as HTMLElement;
    }

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    get token(): string {
        return this.$store.getters.token;
    }

    setApplicationId() {
        this.$store.dispatch('applicationCode', this.applicationId);
    }

    public onToggleMenuSidebar(): void {
        this.$emit('toggle-menu-sidebar');
    }
}
