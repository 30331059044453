import Repository from './Repository';

const resource = 'users';

export default {
    getPaginated(
        page: number,
        limit: number,
        status: boolean = true,
        criteria: string = ''
    ) {
        return Repository.get(
            `${resource}/paginate?page=${page}&limit=${limit}${`&status=${status}`}${
                criteria ? `&criteria=${criteria}` : ''
            }`
        );
    },
    getUser() {
        return Repository.get(`${resource}`);
    },
    getAll() {
        return Repository.get(`${resource}/paginate`);
    },
    saveUser(data: any) {
        return Repository.post(`${resource}`, data);
    },
    updateUser(userId: number, data: any) {
        return Repository.put(`${resource}/${userId}`, data);
    },
    changeUserStatus(userId: number, data: any) {
        return Repository.post(`${resource}/${userId}/change-status`, data);
    },
    assignRoles(data: any) {
        return Repository.post(`${resource}/roles`, data);
    },
    assignPermissions(data: any) {
        return Repository.post(`${resource}/permissions`, data);
    },
    associatedPermissions(userId: number) {
        return Repository.get(`${resource}/${userId}/permissions`);
    },
    associatedRoles(userId: number) {
        return Repository.get(`${resource}/${userId}/roles`);
    }
};
