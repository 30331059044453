import {IUser} from '@/interfaces/user';
import PermissionsRepository from '@/repositories/PermissionsRepository';
import {Options, Vue} from 'vue-class-component';
import {IApplication} from '@/models/application.model';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, helpers} from '@vuelidate/validators';

@Options({
    name: 'permissions-create-edit',
    validations: {
        applicationId: {
            required: helpers.withMessage(
                'Seleccione una aplicacion para el permiso',
                required
            )
        },
        permissionName: {
            required: helpers.withMessage(
                'Ingrese un nombre para el permiso',
                required
            )
        }
    },
    mounted() {
        this.v$.$validate();
    }
})
export default class PermissionsCreateEdit extends Vue {
    permissionId: number;
    applications: IApplication[] = [];
    applicationId: string = '';
    permissionName: string = '';
    isLoading: boolean = false;
    editMode: boolean = false;
    selectIsLoading: boolean = false;

    private toast = useToast();
    v$: any = useVuelidate();

    // Declare created lifecycle hook
    created() {
        this.getApplications();
        const permission = parseInt(this.$route.params.permission?.toString());

        if (permission && permission > 0) {
            const {name, applicationId} = this.$route.params;

            this.editMode = true;
            this.permissionId = permission;
            if (!name || !applicationId) {
                this.$router.push({name: 'permission-list'});
            }

            this.setName(name?.toString(), applicationId?.toString());
        }
    }

    get user(): IUser {
        return this.$store.getters.user;
    }

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    setName(permissionName: string, permissionApplicationId: string) {
        const permission = permissionName.replace(
            `${permissionApplicationId}.`,
            ''
        );
        this.permissionName = permission;
        this.applicationId = permissionApplicationId;
    }

    async getApplications() {
        this.selectIsLoading = true;
        await ApplicationRepository.getApplication()
            .then((Success) => {
                this.applications = Success.data;
            })
            .catch((error) => {
                const {status} = error;

                if (status === 500)
                        this.toast.error('Error interno en el servidor');

                if (!status) {
                    this.toast.error(`${error}`);
                    return;
                }
            })
            .finally(() => {
                this.selectIsLoading = false;
            });
    }

    saveApplication() {
        this.v$.$validate();
        if (!this.v$.$error) {
            if (this.editMode == false) {
                this.createPermisssion();
            } else {
                this.updatePermission();
            }
        } else {
            this.toast.error(' Por favor, complete este formulario');
        }
    }

    async createPermisssion() {
        try {
            const data = {
                name: `${
                    this.applicationId
                }.${this.permissionName.toUpperCase()}`,
                applicationId: this.applicationId
            };
            this.isLoading = true;
            await PermissionsRepository.savePermission(data)
                .then(() => {
                    this.toast.success('Permiso creado');
                    this.applicationId = '';
                    this.permissionName = '';
                })
                .catch((err) => {
                    const {data, status} = err.data;
                    if (data.error === 'NAME_ALREADY_EXISTS') {
                        this.toast.error('El nombre del permiso ya existe');
                        this.permissionName = '';
                        return;
                    }

                    if (status === 500)
                        this.toast.error('Error interno en el servidor');

                    if (!status) {
                        this.toast.error(`${err}`);
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error) {
            this.toast.error('Error inesperado en la aplicación');
        }
    }

    async updatePermission() {
        try {
            const data = {
                name: `${
                    this.applicationId
                }.${this.permissionName.toUpperCase()}`,
                applicationId: this.applicationId
            };
            this.isLoading = true;

            await PermissionsRepository.updatePermission(
                this.permissionId,
                data
            )
                .then(() => {
                    this.applicationId = '';
                    this.permissionName = '';
                    this.toast.success('Permiso actualizado');
                })
                .catch((err: any) => {
                    const {data, status} = err;
                    if (status === 422) {
                        if (data?.error === 'NAME_ALREADY_EXISTS') {
                            this.toast.error(
                                'El nombre del permiso, ya existe'
                            );
                            this.applicationId = '';
                    this.permissionName = '';
                            return;
                        }
                    }

                    if(status=== 500)this.toast.error('Error interno en el servidor');

                    if (!status){
                        this.toast.error(
                            `${err}`
                        );
                        return;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (error) {
            console.log(error);
            this.toast.error('Error inesperado al actualizar permiso');
        }
    }

    goToPermissionsList() {
        this.$router.push({name: 'permission-list'});
    }
}
