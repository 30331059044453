import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import {IApplication} from '@/models/application.model';
import {IPermission} from '@/models/permission.model';
import {IUserPaginate} from '@/models/user.paginate.model';
import {IUser as User} from '@/models/user.model';
import PermissionsRepository from '@/repositories/PermissionsRepository';
import UserRepository from '@/repositories/UsersRepository';
import ApplicationRepository from '@/repositories/ApplicationRepository';
import useVuelidate from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {required, minLength} from '@vuelidate/validators';

@Options({
    name: 'asociate-permissions',
    validations: {
        selectedUsers: {
            required,
            minLength: minLength(1)
        },
        applicationId: {required},
        selectedPermissions: {required, minLength: minLength(1)}
    },
    mounted() {
        this.getApplications();
        const userId = parseInt(this.$route.params.user?.toString());
        if (userId > 0) {
            this.editMode = true;
            this.setData(userId);
        } else {
            this.getResults();
        }
    }
})
export default class UsersAsociatePermissions extends Vue {
    users: User[] = [];
    applicationId: string = '';
    applications: IApplication[] = [];
    permission: IPermission;
    permissions: [] = [];
    selectedPermissions: IPermission[] = [];
    selectedUsers: number[] = [];
    private toast = useToast();
    v$: any = useVuelidate();
    page = 1;
    records = 0;
    perPage = 5;
    loading: boolean = false;
    editMode: boolean = false;
    isBtnLoading: boolean = false;

    get applicationCode(): string {
        return this.$store.getters.applicationCode;
    }

    async getResults() {
        this.loading = true;
        const {data} = await UserRepository.getPaginated(
            this.page,
            this.perPage
        );
        this.records = data.total;
        this.users = data.data;
        this.loading = false;
    }

    setData(userId: number): any {
        this.selectedUsers.push(userId);
        const {created_at, name, email} = this.$route.params;
        if (!name || !email || !created_at) {
            this.$router.push({name: 'users-associated-permissions'});
        }
        this.users = [
            {
                id: userId,
                created_at: created_at?.toString(),
                name: name?.toString(),
                email: email?.toString(),
                updated_at: null
            }
        ];
        this.getPermissionsUser(userId);
    }

    getPermissionsUser(userId: number) {
        UserRepository.associatedPermissions(userId).then((response) => {
            this.selectedPermissions = response.data;
        });
    }

    getPermissionList() {
        this.isBtnLoading = true;
        PermissionsRepository.getPermission(this.applicationId)
            .then((response) => {
                this.permissions = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.isBtnLoading = false;
            });
    }

    async getApplications() {
        this.isBtnLoading = true;
        await ApplicationRepository.getApplication()
            .then((Success) => {
                this.applications = Success.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.isBtnLoading = false;
            });
    }

    addPermisison() {
        const permissionElement = this.selectedPermissions.some(
            (permission) => permission.id === this.permission.id
        );
        if (permissionElement) {
            this.toast.error('Ya existe el elemento');
        } else {
            this.selectedPermissions.push(this.permission);
        }
    }

    removePermission(permissionIndex: number) {
        this.selectedPermissions.splice(permissionIndex, 1);
    }

    goToUsersList() {
        this.$router.push({name: 'users-associated-permissions'});
    }

    saveAsociation() {
        this.v$.$validate();
        if (!this.v$.selectedUsers.$invalid && this.editMode) {
            this.createAssociation();
        }

        if (!this.v$.$error && this.editMode === false) {
            // if ANY fail validation
            this.createAssociation();
        } else {
            if (this.v$.selectedUsers.$invalid) {
                this.toast.error(' Por favor, seleccione un usuario');
            }
            if (
                this.v$.selectedPermissions.$invalid &&
                this.editMode === false
            ) {
                this.toast.error(' Por favor, seleccione un permiso');
            }
        }
    }

    uniqueCheck(e: any) {
        this.selectedUsers = [];
        if (e.target.checked) {
            this.selectedUsers = [e.target.value];
        }
    }

    async createAssociation() {
        try {
            this.isBtnLoading = true;
            const data = {
                userId: Number.parseInt(this.selectedUsers[0].toString(), 10),
                permissions: this.selectedPermissions.map((permission) => {
                    return permission.id;
                })
            };

            await UserRepository.assignPermissions(data).then((response) => {
                this.isBtnLoading = false;
                if (this.editMode) {
                    this.toast.success(
                        'Permisos asociados, actualizados correctamente'
                    );
                } else {
                    this.toast.success('Permisos asociados correctamente');
                }
            });
        } catch (error) {
            console.log(error);
            this.toast.error('Error inesperado');
            this.isBtnLoading = false;
        }
    }
}
