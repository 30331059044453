import Repository from './Repository';

const resource = 'auth';

export default {
    authenticate(data: any) {
        return Repository.post(`${resource}/login`, data);
    },
    logout() {
        return Repository.post(`${resource}/logout`, {});
    },
    refreshToken() {
        return Repository.post(`${resource}/refresh`, {});
    }
};
